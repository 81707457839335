import logo from "../logo.svg";
import { Button } from "react-bootstrap";
import Logo from "../logo_big.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";

const Cover = ({ connect }) => {
  const connectWallet = async () => {
    try {
      await connect();
    } catch (e) {
      console.log({ e });
    }
  };
  return (
    <>
      <img src={Logo} alt="logo" width="200px" height="200px" />
      <p>Cauris Direct</p>
      <ConnectButton />
    </>
  );
};

export default Cover;
