import React from "react";
import ReactDOM from "react-dom";
// import {
//   ContractKitProvider,
//   Mainnet,
//   NetworkNames,
// } from "@celo-tools/use-contractkit";
// import { CeloProvider, Mainnet, NetworkNames } from "@celo/react-celo";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import "@celo-tools/use-contractkit/lib/styles.css";
// import "@celo/react-celo/lib/styles.css";
import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { celo } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

import "react-toastify/dist/ReactToastify.min.css";

const { chains, publicClient } = configureChains([celo], [publicProvider()]);

const { connectors } = getDefaultWallets({
  appName: "Cauris Direct",
  projectId: "008f59d3981367662ebbd08ad144e49a",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

ReactDOM.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
    {/* <CeloProvider
      networks={[Mainnet]}
      defaultNetwork="Mainnet"
      // network={{
      //   name: NetworkNames.Mainnet,
      //   rpcUrl: "https://forno.celo.org",
      //   graphQl: "https://alfajores-blockscout.celo-testnet.org/graphiql",
      //   explorer: "https://celoscan.io",
      //   chainId: 42220,
      // }}
      dapp={{
        name: "Cauris Direct2",
        description: "Direct investments into Fintechs",
        walletConnectProjectId: "008f59d3981367662ebbd08ad144e49a",
      }}
      connectModal={{
        // This options changes the title of the modal and can be either a string or a react element
        title: <span>Connect your Wallets</span>,

        providersOptions: {
          // This option hides specific wallets from the default list
          // hideFromDefaults: [
          //   SupportedProvider.MetaMask,
          //   SupportedProvider.PrivateKey,
          //   SupportedProvider.CeloExtensionWallet,
          //   SupportedProvider.Valora,
          // ],

          // This option hides all default wallets
          hideFromDefaults: false,

          // This option toggles on and off the searchbar
          searchable: true,
        },
      }}
    >
      <App />
    </CeloProvider> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
