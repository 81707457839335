import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import {
  useContractRead,
  useAccount,
  useConnect,
  useDisconnect,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { usdcContractConfig, poolContractConfig } from "../hooks/contracts";

import {
  GetTerm,
  GetBasisPoints,
  GetVaultBalance,
  GetIsInvestor,
  GetInvestments,
  GetApproved,
  GetStableCoinBalance,
} from "../utils/useBalance";
import Web3 from "web3";
import { formatBigNumber } from "../utils";
const onWithdraw = async (amount, since) => {};

function isMature(investTime, term) {
  let rawDate = Number(investTime) + Number(term);
  const now = Date.now() / 1000;

  if (now < rawDate) {
    return false;
  } else {
    return true;
  }
}

function converToMaturity(since, term) {
  let rawDate = 1000 * (Number(since) + Number(term));
  let date = new Date(rawDate);
  return date.toDateString();
}

function secondsToDhms(seconds) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
}

function Term() {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "timePeriod",
    args: [],
    enabled: true,
  });
  return (
    <div>
      Term: <b> {secondsToDhms(data)}</b>
    </div>
  );
}
function InterestRate() {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "percentageBasisPoints",
    args: [],
    enabled: true,
  });
  return (
    <div>
      Interest: <b> {Number(data) / 10}%</b>
    </div>
  );
}

const VaultBalance = (props) => {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "stableCoinBalance",
    args: [],
    enabled: true,
  });
  props.setVaultBalance(data);
  return (
    <div>
      <div>
        Vault Balance: <b> {formatBigNumber(props.vaultBalance)}</b>
      </div>
      <div>
        Wallet cUSD Balance: <b> {formatBigNumber(props.stableCoinBalance)}</b>
      </div>
    </div>
  );
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const InsufficentBalanceView = (props) => {
  return (
    <div className="d-grid gap-2 d-md-block">
      <div className="d-flex justify-content-between" width="100%">
        {" "}
        <p>&nbsp;</p>
        <Form.Control
          autofocus
          type="number"
          placeholder="Deposit Amount"
          onChange={(e) => props.setDepositAmount(e.target.value)}
          value={props.depositAmount}
        />{" "}
        <Button className="m-2" variant="danger" size="lg">
          Deposit
        </Button>
      </div>
      <div className="text-danger d-md-block"> Insufficient cUSD Balance</div>
    </div>
  );
};

const DepositTokenView = (props) => {
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { write, data, error, isLoading, isError } = useContractWrite({
    ...poolContractConfig,
    functionName: "deposit",
    args: [100 * 10 ** 18],
  });
  const {
    data: receipt,
    isLoading: isPending,
    isSuccess,
  } = useWaitForTransaction({ hash: data?.hash });

  if (isSuccess && isInitialRender) {
    sleep(3000).then(() => {
      window.location.reload();
    });

    // props.setVaultBalance(50);
    // setIsInitialRender(!props.rerender);
  }

  return (
    <div className="d-grid gap-2 d-md-block">
      <div className="d-flex justify-content-between" width="100%">
        {" "}
        <p>&nbsp;</p>
        <Form.Control
          autofocus
          type="number"
          placeholder="Deposit Amount"
          onChange={(e) => props.setDepositAmount(e.target.value)}
          value={props.depositAmount}
        />{" "}
        <Button
          className="m-2"
          variant="success"
          size="lg"
          onClick={() => write()}
        >
          Deposit
        </Button>
      </div>
    </div>
  );
};

const ApproveView = (props) => {
  const [isInitialRender, setIsInitialRender] = useState(true);

  const { write, data, error, isLoading, isError } = useContractWrite({
    ...usdcContractConfig,
    functionName: "approve",
    args: [poolContractConfig.address, props.depositAmount * 10 ** 18],
  });
  const {
    data: receipt,
    isLoading: isPending,
    isSuccess,
  } = useWaitForTransaction({ hash: data?.hash });

  if (isSuccess && isInitialRender) {
    sleep(3000).then(() => {
      window.location.reload();
    });

    // props.setVaultBalance(50);
    // setIsInitialRender(!isInitialRender);
  }
  return (
    <div className="d-grid gap-2 d-md-block">
      <div className="d-flex justify-content-between" width="100%">
        {" "}
        <p>&nbsp;</p>
        <Form.Control
          type="number"
          placeholder="Deposit Amount"
          onChange={(e) => props.setDepositAmount(e.target.value)}
          value={props.depositAmount}
        />{" "}
        <Button
          className="m-2"
          variant="primary"
          size="lg"
          onClick={() => write()}
        >
          Approve
        </Button>
      </div>
    </div>
  );
};

const DepositView = (props) => {
  const { address } = useAccount();

  const isInvestor = GetIsInvestor(address);

  // const [allowanceAmount, setAllowanceAmount] = useState(0);
  const depositAmount = props.depositAmount;
  const setDepositAmount = props.setDepositAmount;
  const setVaultBalance = props.setVaultBalance;
  const vaultBalance = props.vaultBalance;

  const allowanceAmount = GetApproved(address);
  // const stableCoinBalance = GetStableCoinBalance(address);

  // setAllowanceAmount(GetApproved(address));

  const approveSpend = async () => {
    //   try {
    //     setLoading(true);
    //     await deposit(poolContract, performActions, depositAmount);
    //   } catch (e) {
    //     console.log({ e });
    //   } finally {
    //     setLoading(false);
    //     updateInvesments();
    //   }
  };

  return (
    <div>
      {" "}
      {!isInvestor ? (
        <div>
          <b>Account not registered as an authorized investor.</b>
        </div>
      ) : allowanceAmount >= depositAmount * 10 ** 18 &&
        props.stableCoinBalance >= depositAmount * 10 ** 18 ? (
        <DepositTokenView
          depositAmount={depositAmount}
          setDepositAmount={setDepositAmount}
          vaultBalance={props.vaultBalance}
          setVaultBalance={props.setVaultBalance}
          setRerender={props.setRerender}
          rerender={props.rerender}
        />
      ) : allowanceAmount >= depositAmount * 10 ** 18 &&
        props.stableCoinBalance < depositAmount * 10 ** 18 ? (
        <InsufficentBalanceView
          depositAmount={depositAmount}
          setDepositAmount={setDepositAmount}
        />
      ) : (
        <ApproveView
          depositAmount={depositAmount}
          setDepositAmount={setDepositAmount}
        />
      )}
    </div>
  );
};

function InvestViews() {
  const timePeriod = GetTerm();
  const basisPoints = GetBasisPoints();

  const { address, connect } = useAccount();
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "getInvestments",
    args: [address],
    enabled: true,
  });

  return (
    <div>
      {" "}
      {isSuccess &&
        data?.map((data) => (
          <div>
            {data.amount > 0 ? (
              <Card className="text-left w-50 m-auto">
                <Card.Body>
                  <div className="d-flex justify-content-between" width="100%">
                    <div>
                      Amount: <b> {formatBigNumber(data.amount)} cUSD</b>{" "}
                      <br></br>
                      <br></br> Interest: <b> {Number(basisPoints) / 10}%</b>
                      <br></br>
                      Term: <b> {secondsToDhms(timePeriod)}</b>
                      <br></br>
                      Maturity:{" "}
                      <b>{converToMaturity(data.since, timePeriod)}</b>
                    </div>
                    {isMature(data.since, timePeriod) ? (
                      <Button
                        className="m-2"
                        variant="success"
                        size="md"
                        onClick={() => onWithdraw(data.amount, data.since)}
                        amount={data.amount}
                        since={data.since}
                      >
                        Withdraw
                      </Button>
                    ) : (
                      <Button
                        className="m-2"
                        variant="disabled"
                        size="md"
                        onClick={() => onWithdraw(data.amount, data.since)}
                        amount={data.amount}
                        since={data.since}
                      >
                        Not ready for withdrawal
                      </Button>
                    )}
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <div>{data.amount}</div>
            )}
          </div>
        ))}
    </div>
  );
}

const style = {
  "background-color": "#221b43",
};

const insufficientBalanceStyle = {
  color: "#221b43",
};

export const Dashboard = ({ address }) => {
  const [loading, setLoading] = useState(false);
  const [depositAmount, setDepositAmount] = useState(0);
  const [vaultBalance, setVaultBalance] = useState(0);
  const [rerender, setRerender] = useState(false);
  var stableCoinBalance = GetStableCoinBalance(address);

  return (
    <div className={style}>
      <Card className="text-left w-50 m-auto">
        <Card.Header>
          <img
            src="https://storage.googleapis.com/cauris_deep_dive/Icon-01.jpg"
            width={25}
            height={25}
            alt="YoFio"
          />
          Cauris Direct
        </Card.Header>
        <Card.Body className="mt-3">
          {/* <Card.Title>Count: {count}</Card.Title> */}
          <div className="d-flex justify-content-between" width="100%">
            <img src="https://image.pitchbook.com/9AaRPNBA7gg72Pjr8gUOFIGtCFv1610463636984_200x200" />
            <div>
              <Term />
              <InterestRate />
              <VaultBalance
                vaultBalance={vaultBalance}
                setVaultBalance={setVaultBalance}
                stableCoinBalance={stableCoinBalance}
              />
              {/* Interest: <b> {Number(basisPoints) / 10}%</b>
              <br></br>
              cUSD Balance: <b> ${stableCoinBalance}</b> */}
            </div>
          </div>
          <Card.Body>
            YoFio provides business financing to informal neighborhood stores to
            help replenish inventory to grow their stores. YoFio has developed a
            proprietary credit underwriting model that leverages machine
            learning to estimate a store’s true sales volume and risk profile
            based on a wide range of alternative data points, including pictures
            of the store and its inventory. YoFio then works with large
            wholesalers to enable retailers to buy inventory via an established
            credit line, all of which is documented digitally.
          </Card.Body>
          <DepositView
            depositAmount={depositAmount}
            setDepositAmount={setDepositAmount}
            vaultBalance={vaultBalance}
            setVaultBalance={setVaultBalance}
            setRerender={setRerender}
            rerender={rerender}
            stableCoinBalance={stableCoinBalance}
          />
        </Card.Body>
      </Card>
      {/* {investViews} */}
      <InvestViews />
    </div>
  );
};

export default Dashboard;
