import React from "react";
import { Container, Nav } from "react-bootstrap";
// import { useContractKit } from "@celo-tools/use-contractkit";
import { Notification } from "./components/ui/Notifications";
import Wallet from "./components/Wallet";
import Cover from "./components/Cover";
import Dashboard from "./components/Dashboard";
// import { useBalance, usePoolContract, useStableCoinContract } from "./hooks";
// import { usePoolContract, useStableCoinContract } from "./hooks";
import { usdcContractConfig } from "./hooks/contracts";

import { GetBalance } from "./utils/useBalance";

import "./App.css";

// import { useCelo } from "@celo/react-celo";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { disconnect } from "@wagmi/core";
const App = function AppWrapper() {
  const { address, connect } = useAccount();
  const balance = GetBalance(address);

  // const poolContract = usePoolContract();
  // const stableCoinContract = useStableCoinContract();
  return (
    <>
      <Notification />
      {address ? (
        <Container fluid="md">
          <Nav className="justify-content-end pt-3 pb-5">
            <Nav.Item>
              <Wallet
                address={address}
                amount={balance}
                symbol="CELO"
                destroy={disconnect}
              />
            </Nav.Item>
          </Nav>
          <main>
            <Dashboard address={address} />
          </main>
        </Container>
      ) : (
        // display cover if user is not connected
        <div className="App">
          <header className="App-header">
            <Cover connect={connect} />
          </header>
        </div>
      )}
    </>
  );
};

export default App;
