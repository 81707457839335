import { useState, useEffect, useCallback } from "react";
// import { useContractKit } from "@celo-tools/use-contractkit";
// import { useCelo } from "@celo/react-celo";
import { useContractRead, useAccount, useConnect, useDisconnect } from "wagmi";
import { usdcContractConfig, poolContractConfig } from "../hooks/contracts";

export function GetBalance(address) {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...usdcContractConfig,
    functionName: "balanceOf",
    args: [address],
    enabled: Boolean(address),
  });

  return data;
}

export function GetApproved(address) {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...usdcContractConfig,
    functionName: "allowance",
    args: [address, poolContractConfig.address],
    enabled: Boolean(address),
  });
  return data;
}

export function GetStableCoinBalance(address) {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...usdcContractConfig,
    functionName: "balanceOf",
    args: [address],
    enabled: Boolean(address),
  });
  return data;
}

export function GetTerm() {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "timePeriod",
    args: [],
    enabled: true,
  });
  return data;
}

export function GetBasisPoints() {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "percentageBasisPoints",
    args: [],
    enabled: true,
  });
  if (!data) {
    return 0;
  }
  return data;
}

export function GetVaultBalance() {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "stableCoinBalance",
    args: [],
    enabled: true,
  });

  if (!data) {
    return 0;
  }
  return data;
}

export function GetIsInvestor(address) {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "hasRole",
    args: [
      "0xb165298935924f540e4181c03493a5d686c54a0aaeb3f6216de85b7ffbba7738",
      address,
    ],
    enabled: true,
  });

  if (!data) {
    return 0;
  }
  return data;
}

export const GetInvestments = async (account) => {
  const { data, error, isLoading, isSuccess } = useContractRead({
    ...poolContractConfig,
    functionName: "getInvestments",
    args: [account],
    enabled: true,
  });

  if (!data) {
    return 0;
  }
  return data;
};

// export function SetBalance() {
//   const { address, disconnect, connect } = useAccount();
//   console.log("HI", address);

//   const [balance, setBalance] = useState(0);

//   const { data, error, isLoading, isSuccess } = useContractRead({
//     ...usdcContractConfig,
//     functionName: "balanceOf",
//     args: [address],
//     enabled: Boolean(address),
//   });
//   console.log("USE BALANCE", isSuccess);
//   console.log("USE BALANCE data", data);

//   return data;

//   // const getBalance = useCallback(async () => {
//   //   // fetch a connected wallet token balance
//   //   console.log("address", address);
//   //   const value = useBalance({
//   //     address: address,
//   //     token: "0x765DE816845861e75A25fCA122bb6898B8B1282a",
//   //   });
//   //   setBalance(value);
//   // }, [address]);

//   // useEffect(() => {
//   //   if (address) getBalance();
//   // }, [address, getBalance]);

//   return {
//     data,
//   };
// }
