import { ERC20_DECIMALS } from "./constants";

// format a wallet address
export const truncateAddress = (address) => {
  if (!address) return;
  return (
    address.slice(0, 5) +
    "..." +
    address.slice(address.length - 4, address.length)
  );
};

// convert from big number
export const formatBigNumber = (num) => {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if (!num) return "$0";
  var bigNum = Number(num) / 10 ** 18;

  return USDollar.format(bigNum);
};
